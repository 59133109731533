import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DELIVERY_DESTINATION_CLINIC, UPS_TRACK_LOCALE_MAP, trackingLink} from '../../../actions/deliveries';
import {NavLink} from 'react-router-dom';
import remoteLog from '../../../common/logging';


const DELIVERY_ON_STATUSES = [
    'S_PRODUCTION_PRINTING',
    'S_PRODUCTION_FABRICATING',
    'S_FINAL_STAGE_READY',
];


const mapStateToProps = (state) => {
    return {
        lang: state.intl,
        patient: state.patient,
        services: state.services
    }
}

class PatientStatus extends Component {
    componentDidCatch(e) {
        remoteLog(e, 'patient_status');
    }

    renderDelivery() {
        const {patient, lang, intl} = this.props;
        const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

        if (patient && DELIVERY_ON_STATUSES.includes(patient.status)
            && patient.deliveries && Array.isArray(patient.deliveries) && patient.deliveries.length) {
            let delivery = null;
            const filtered = patient.deliveries.filter(
                delivery => delivery.destination == DELIVERY_DESTINATION_CLINIC && delivery.called_by_phone
                    && ((delivery.pickup_date && !delivery.track_number) || (delivery.pickup_date && delivery.track_number))
            );
            if (filtered && filtered.length) {
                delivery = filtered[0];
            }
            if (delivery !== null && delivery.track_number !== null) {
                const pickup_date = moment(delivery.pickup_date);
                if (moment().diff(pickup_date, 'months', true) <= 1) {
                    return (
                        <div>
                            <p><label id="status-info-side-block-aligner-delivery-label">
                                <strong id="status-info-side-block-aligner-delivery-value"><FormattedMessage
                                    id="pat.block.deliveries.aligner.delivery"/></strong>
                            </label>
                            </p>
                            <p>
                                <label
                                    id="status-info-side-block-ups-site-track-label"><strong>{delivery.track_number}</strong></label>&nbsp;&nbsp;
                                <a
                                   id="status-info-side-block-ups-site-track-link"
                                   href={trackingLink(delivery.track_number, delivery.type, upsLocale)}
                                   rel="noopener noreferrer" target="_blank"
                                >
                                    <i className="icon-info font-green" id="status-info-side-block-ups-site-track-value"
                                       title={intl.formatMessage({id: "pat.block.deliveries.ups.site.track"})}/>
                                </a>
                            </p>
                            <NavLink to={`/pages/patient/${patient.patient_id}/deliveries`}>
                                <strong id="status-info-side-block-all-deliveries">(<FormattedMessage
                                    id="pat.block.deliveries.aligner.all"/>)</strong>
                            </NavLink>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    render() {
        return (
            <div className="portlet light bordered" id="status-info-side-block">
                <div className="portlet-title">
                    <div className="caption" id="status-info-side-block-name">
                        <i className="icon-clock font-green"></i>
                        <span className="caption-subject font-green bold uppercase"><FormattedMessage
                            id="pat.block.status.header"/></span>
                    </div>
                </div>
                <div className="portlet-body" id="status-info-side-block-body">
                    <label id="status-info-side-block-label"><strong> <FormattedMessage id="pat.block.status.status"/>
                    </strong></label>
                    <p id="status-info-side-block-value"><FormattedMessage id={this.props.patient.status}/></p>
                    {this.renderDelivery()}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(injectIntl(PatientStatus));
