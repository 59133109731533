import React, { Component } from 'react';
import { connect } from 'react-redux';

import PatientId from './patient_show/patient_id';
import PatientStatus from './patient_show/patient_status';
import PatientDeliveryBlock from './patient_show/patient_delivery';
import PatientPayment from './patient_show/patient_payment';
import Patient3DPlan from './patient_show/patient_3d_plan';
import PatientCorrection from './patient_show/patient_corection';
import PatientCommentsSmile from './patient_show/patient_comments_smile';
import PatientCommentsPredict from './patient_show/patient_comments_predict';
import PatientCard from './patient_show/patient_card';
import PatientInvoice from './patient_show/patient_invoice';
import remoteLog from '../../common/logging';
import Loader from '../../components/common/loadingInProgress';
import PatientAskQuestion from "./patient_ask_question";
import PatientAdditionalAlignersCard from "./patient_show/patient_additional_aligners";
import { getPatientId } from '../../actions/get_patient_id';
import { getToken } from '../../actions/get_token';
import { clearExtraServices, getExtraServices } from '../../actions/extra_services';
import { deployedRussia, deployedUSA } from '../../common/utils';
import PatientScans from "./patient_show/patient_scans";


const mapStateToProps = (state) => {
    return {
        user: state.user,
        patient: state.patient,
        token: state.token,
        intl: state.intl
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getData: (patient_id) => dispatch(getPatientId(patient_id, true)),
        getToken: () => dispatch(getToken()),
        clearExtraServices: () => dispatch(clearExtraServices()),
        getExtraServices: (patient_id) => dispatch(getExtraServices(patient_id))
    }
}


class PatientPageBody extends Component {


    UNSAFE_componentWillMount() {
        const patient_id = this.props.match.params.patient_id;
        this.props.getData(patient_id);
        this.props.getToken();
        this.props.clearExtraServices();
        this.props.getExtraServices(patient_id);
    }

    componentDidMount() {
        const { user } = this.props;
    }

    componentDidCatch(e) {
        remoteLog(e, 'patient_page_body');
    }

    renderQuestionSection() {
        if (this.props.patient.status === 'S_3D_PLAN_APPROVAL' && deployedRussia() === true) {
            return (
                <div className="portlet light bordered" id="question-side-block" style={{textAlign: "center"}}>
                    <PatientAskQuestion/>
                </div>
            );
        }
        return null;
    }


    render() {
        if (this.props.patient && this.props.patient.patient_id && this.props.patient.course) {
            const correction = this.props.patient.course.correction.slice(-1)[0].order_options.can_order_correction;
            const status_end = this.props.patient.course.correction.slice(-1)[0].order_options.can_end_treatment;
            const status_retainers = this.props.patient.course.correction.slice(-1)[0].order_options.can_order_retainers;
            const status_additional_aligners = this.props.patient.course.correction.slice(-1)[0].order_options.can_order_additional_aligners;
            const uploadFrom3Shape = !!this.props.user.privileges.has_3shape_scanner;
            const bonusPatient = this.props.patient.bonus_set_free;
            const medit_id = this.props.patient.medit_id;

            return (
                <div className="row">
                    <div className="col-md-8">
                        {this.props.token && this.props.patient.media['3d-plan'].response ? <Patient3DPlan/> : null}
                        {deployedUSA() === true ? <PatientCommentsPredict {...this.props}/> : null}
                        {deployedUSA() === true ? <PatientCorrection/> : null}
                        {deployedUSA() === true && status_additional_aligners ? <PatientAdditionalAlignersCard/> : null}
                        {deployedRussia() === true && uploadFrom3Shape ? <PatientScans /> : null}
                        {deployedRussia() === true && (correction || status_end || status_retainers || bonusPatient) ? <PatientCorrection/> : null}
                        {this.props.patient && this.props.patient.status !== 'S_UNFILLED_CASE'
                            ? <PatientInvoice {...this.props}/>
                            : null
                        }
                        {deployedRussia() === true ? <PatientCommentsSmile {...this.props}/> : null}
                        <PatientCard />
                    </div>
                    <div className="col-md-4">
                        <PatientId/>
                        <PatientStatus/>
                        <PatientPayment/>
                        <PatientDeliveryBlock/>
                        {this.renderQuestionSection()}
                        {deployedRussia() === true && status_additional_aligners ? <PatientAdditionalAlignersCard/> : null}

                    </div>
                </div>
            )
        }
        return <div><Loader/></div>;
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientPageBody);

