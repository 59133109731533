import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import ProbablyFormattedMessage from '../../probably_formatted_message';
import remoteLog from '../../../common/logging';
import PersonName from "../../common/PersonName";
import FormatDate from "../../common/FormatDate";
import { deployedUSA } from '../../../common/utils';
import { CASE_CBCT, COURSE_VERSION_V2} from '../../../common/courses';


const mapStateToProps = (state) => {
  return {
    patient: state.patient,
    lang: state.intl
  }
}


class PatientId extends Component {

  constructor(props) {
    super(props);

    this.showDates = this.showDates.bind(this);
    this.fm = this.fm.bind(this);
    this.renderCourseName = this.renderCourseName.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_id');
  }

  fm(id) {
    if (deployedUSA()) {
      return this.props.intl.formatMessage({ id: id });
    }
  }

  showDates() {
    if (this.props.patient && this.props.patient.course && this.props.patient.course.correction.length > 0) {
      const start_date = this.props.patient.course.correction.slice(-1)[0].start_date;
      const plan_date = this.props.patient.course.correction.slice(-1)[0].plan_date;
      const steps_count_total = this.props.patient.course.correction.slice(-1)[0].steps_count_total;
      const approval_timestamp = this.props.patient.course.correction.slice(-1)[0].approval_timestamp;
      const deliveryEstimate = this.props.patient.delivery_estimate;
      const deliveryAdditionalEstimate = this.props.patient.delivery_additional_estimate;
      const deliveryRetainersEstimate = this.props.patient.delivery_retainers_estimate;

      return (
        <div>
          {this.props.patient.course.correction.map((c, index) =>
            index > 0 ?
              <div key={index}>
                <label><strong><FormattedMessage id="pat.block.right.correction" /><span> {index} </span></strong></label>
                <p><FormatDate value={c.timestamp} date /></p>
              </div>
              : null
          )}
          {start_date !== null
            ? (
              <div>
                <label><strong> <FormattedMessage id="pat.block.right.start_date" /> </strong></label>
                <p><FormatDate value={start_date} date /></p>
              </div>
            ) : null
          }
          {plan_date !== null
            ? (
              <div>
                <label><strong> <FormattedMessage id="pat.block.right.plan_date" /> </strong></label>
                <p><FormatDate value={plan_date} date /></p>
              </div>
            ) : null
          }
          {approval_timestamp !== null
            ? (
              <div data-toggle="tooltip" title={this.fm("tooltip_3d_plan_approval_date")}>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.plan_approval" /> </strong>
                  <p><FormatDate value={approval_timestamp} date /></p>
                </label>
              </div>
            ) : null
          }
          {deployedUSA() === true && deliveryEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.aligners.eta" /> </strong>
                  <p><FormatDate value={deliveryEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {deployedUSA() === true && deliveryAdditionalEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.additional.eta" /> </strong>
                  <p><FormatDate value={deliveryAdditionalEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {deployedUSA() === true && deliveryRetainersEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.retainers.eta" /> </strong>
                  <p><FormatDate value={deliveryRetainersEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {steps_count_total !== 'None'
            ? (
              <div>
                <label><strong> <FormattedMessage id="p.patient.correction.stages" /> </strong></label>
                <p>{steps_count_total}</p>
              </div>
            ) : null
          }
        </div>
      )
    }
    return null
  }

  renderCourseName() {
    const { patient } = this.props;
    const course_id = patient.course.course_id;
    const course = patient.course.course;
    const cnamePostfix = (patient && patient.course_version == COURSE_VERSION_V2) ? "_V2" : "";


    return (
      <p>
        {course_id === CASE_CBCT ? (
          <img src="/img/deepcbct.png" alt="cbct-icon" style={{ width: '80px', height: '42px', display: 'block' }} />
        ) : (
          <span style={{ display: 'block', color: '#26a1ab', fontWeight: '700' }}>
            <ProbablyFormattedMessage id={`${course}_USA${cnamePostfix}`} />
          </span>
        )
        }
        {!(course === 'C_3D_PLAN' || course === 'C_RETAINERS') ? <ProbablyFormattedMessage id={`${course}_COMMENT_USA${cnamePostfix}`} /> : null}
      </p>
    )
  }


  render() {
    const warranty_end_date = this.props.patient.warranty_end_date;
    const bonus = this.props.patient && this.props.patient.bonus === true;
    const fm = (id) => this.props.intl.formatMessage({ id: id });
    const course = this.props.patient.course.course;

    return (
      <div className="portlet light bordered" id="patient-info-side-block">
        <div className="portlet-title" id="patient-info-side-block-name">
          <div className="caption">
            <i className="icon-user font-green"></i>
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage id="pat.block.right.header" />&nbsp;&nbsp;
              {bonus === true
                ? <img style={{ width: '2rem' }} src="/img/bonus.svg" title={fm("bonus.patient")} />
                : null
              }
            </span>
          </div>
        </div>
        <div className="portlet-body" id="patient-info-side-block-body">
          <label id="patient-info-side-block-id-label"><strong> <FormattedMessage id="pat.block.right.id" /> </strong></label>
          <p id="patient-info-side-block-id-value">{this.props.patient.patient_id}</p>
          <label id="patient-info-side-block-name-label"><strong> <FormattedMessage id="pat.block.right.name" /> </strong></label>
          <p id="patient-info-side-block-name-value"><PersonName person={this.props.patient} /></p>
          <label id="patient-info-side-block-main-course-submission-date-label">
            <strong> <FormattedMessage id="pat.block.right.instructions" /></strong>
          </label>
          <p id="patient-info-side-block-main-course-submission-date-value"><FormatDate value={this.props.patient.timestamp} date /></p>
          {warranty_end_date
            ? (
              <div>
                <label id="patient-info-side-block-main-course-warranty-label">
                  <strong><FormattedMessage id="pat.block.right.warranty_end_date" /></strong>
                </label>
                <p id="patient-info-side-block-main-course-warranty-value"><FormatDate value={warranty_end_date} date /></p>
              </div>
            )
            : null
          }
          {this.showDates()}
          {deployedUSA() && course !== "None"
            ? (
              <div>
                <label className="control-label" id="course-label">
                  <strong> <FormattedMessage id="HEADER_INSTRUCTIONS" /></strong>
                </label>
                {this.renderCourseName()}
              </div>
            ) : null
          }
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps)(injectIntl(PatientId));
