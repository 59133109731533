import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import remoteLog from '../../../common/logging';
import { deployedRussia } from '../../../common/utils';
import FormatNumber from "../../common/FormatNumber";
import { sendInvoiceRequest } from '../../../actions/notifications';

const mapStateToProps = (state) => {
  return {
    patient: state.patient
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      sendInvoice: (cases) => dispatch(sendInvoiceRequest(cases))
  }
}

class PatientPayment extends Component {
  constructor(props) {
    super(props);
    this.togglePaymentInfo = this.togglePaymentInfo.bind(this);
    this.togglePayment = this.togglePayment.bind(this);
    this.state = {
      paymentState: false
    };
  }

  componentDidCatch(e) {
    remoteLog(e, 'patient_payment');
  }

  togglePayment() {
    this.setState({
      paymentState: !this.state.paymentState
    });
    $(this.refs['toggle-div']).slideToggle();
  }

  togglePaymentInfo() {
    if (this.state.paymentState) {
      return (
        <div>
          <a id="payment-info-side-show-button" onClick={this.togglePayment} ref="toggle-button">
            <FormattedMessage id="general.payment.close" />
          </a>
        </div>
      );
    }  else {
      return (
        <div>
          <a id="payment-info-side-show-button" onClick={this.togglePayment} ref="toggle-button">
            <FormattedMessage id="general.payment.show" />
          </a>
        </div>
      );
    } 
  }

  render() {
    const currentPayments = this.props.patient.total_payments;
    const lastPayment = this.props.patient.course.correction.slice(-1)[0];
    const patientId = this.props.patient.patient_id;
    return (
      <div className="portlet light bordered" id="payment-info-side-block">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-wallet font-green"></i>
            <span className="caption-subject font-green bold uppercase" id="payment-info-side-block-name"><FormattedMessage id="pat.block.payment.header" /> </span>
          </div>
        </div>
          { this.togglePaymentInfo() }
          <div className="portlet-body" ref="toggle-div" style={{display: 'none'}} id="payment-info-side-block-body">
              <label id="payment-status-side-block-label"><strong> <FormattedMessage id="pat.block.payment.status" /> </strong></label>
              <p id="payment-status-side-block-value"><FormattedMessage id={ lastPayment.payment_status }/></p>
              {deployedRussia()
                ? (
                  <div>
                    <label id="paid-info-side-block-label"><strong> <FormattedMessage id="pat.block.payment.received" /> </strong></label>
                    <p id="paid-info-side-block-value">
                        <FormatNumber value={currentPayments.paid}/> <FormattedMessage id="pat.block.payment.currency" />
                    </p>
                    <label id="payment-due-side-block-label"><strong> <FormattedMessage id="pat.block.payment.total" /></strong></label>
                    <p id="payment-due-side-block-value">
                        <FormatNumber value={currentPayments.total}/> <FormattedMessage id="pat.block.payment.currency" />
                    </p>
                  </div>
                ) : (
                  <div>
                    <label id="paid-info-side-block-label"><strong><FormattedMessage id="pat.block.payment.currency.usa" /> <FormattedMessage id="pat.block.payment.received" /> </strong></label>
                    <p id="paid-info-side-block-value">
                        <FormattedMessage id="pat.block.payment.currency.usa" /> <FormatNumber value={currentPayments.paid}/>
                    </p>
                    <label id="payment-due-side-block-label"><strong><FormattedMessage id="pat.block.payment.currency.usa" /> <FormattedMessage id="pat.block.payment.total" /></strong></label>
                    <p id="payment-due-side-block-value">
                        <FormattedMessage id="pat.block.payment.currency.usa" /> <FormatNumber value={currentPayments.total}/>
                    </p>
                  </div>
                )
            }
          </div>
          {currentPayments.paid !== currentPayments.total ? (
            <button onClick={(e) => this.props.sendInvoice(patientId)} className='btn green' style={{margin: "5px 0px 0px -5px"}}><FormattedMessage id="pat.block.payment.invoice"/></button>
          ) : null 
          }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPayment);
