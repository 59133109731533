// Memory
export const KB = 1024;
export const MB = KB * KB;
export const GB = KB * KB * KB;

// LIMITs
export const CBCT_MAX_FILESIZE = 1.5 * GB;

// Time
export const SEC = 1000;
export const MIN = SEC * 60;
export const HOUR = MIN * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

// Deploy
export const DEPLOY_USA = 'DEPLOY_USA';
export const DEPLOY_RUSSIA = 'DEPLOY_RUSSIA';

export const CASE_RX_TYPE_STANDARD = 1;
export const CASE_RX_TYPE_SHORT = 2;

// Dental notation types
export const DN_TYPE_FDI = 1
export const DN_TYPE_PALMER = 2
export const DN_TYPE_UNIVERSAL = 3


export const DN_MAP_PALMER: Record<number, string> = {
  18: 'UR8', 17: 'UR7', 16: 'UR6', 15: 'UR5', 14: 'UR4', 13: 'UR3', 12: 'UR2', 11: 'UR1',

  28: 'UL8', 27: 'UL7', 26: 'UL6', 25: 'UL5', 24: 'UL4', 23: 'UL3', 22: 'UL2', 21: 'UL1',

  48: 'LR8', 47: 'LR7', 46: 'LR6', 45: 'LR5', 44: 'LR4', 43: 'LR3', 42: 'LR2', 41: 'LR1',

  38: 'LL8', 37: 'LL7', 36: 'LL6', 35: 'LL5', 34: 'LL4', 33: 'LL3', 32: 'LL2', 31: 'LL1',
}


export const DN_MAP_UNIVERSAL: Record<number, string> = {
  18: '1', 17: '2', 16: '3', 15: '4', 14: '5', 13: '6', 12: '7', 11: '8',

  28: '16', 27: '15', 26: '14', 25: '13', 24: '12', 23: '11', 22: '10', 21: '9',

  48: '32', 47: '31', 46: '30', 45: '29', 44: '28', 43: '27', 42: '26', 41: '25',

  38: '17', 37: '18', 36: '19', 35: '20', 34: '21', 33: '22', 32: '23', 31: '24',
}


export const PLAN_LANG_OPTION_MAP = {
  [DN_TYPE_PALMER]: 'predict_pns',
  [DN_TYPE_UNIVERSAL]: 'predict_uns',
  [DN_TYPE_FDI]: 'predict_fdi'
};


export const COMMENT_TYPE_COMMON = 0;
export const COMMENT_TYPE_CORRECTION = 1;
export const COMMENT_TYPE_REWORK = 2;
export const COMMENT_TYPE_APPROVE = 3;
export const COMMENT_TYPE_CE_QUESTION = 4;
export const COMMENT_TYPE_REWORK_PRESICE = 5;
export const COMMENT_TYPE_ORDER_RETAINERS = 6;

export const COMMENT_TYPE_OPTIONS = {
  [COMMENT_TYPE_REWORK]: {
    color: "orange",
    translation: "COMMENT_TYPE_REWORK"
  },
  [COMMENT_TYPE_CORRECTION]: {
    color: "orange",
    translation: "COMMENT_TYPE_CORRECTION"
  },
  [COMMENT_TYPE_CE_QUESTION]: {
    color: "green",
    translation: "COMMENT_TYPE_CE_QUESTION"
  },
  [COMMENT_TYPE_REWORK_PRESICE]: {
    color: "red",
    translation: "COMMENT_TYPE_REWORK_PRESICE"
  },
  [COMMENT_TYPE_ORDER_RETAINERS]: {
    color: "green",
    translation: "COMMENT_TYPE_ORDER_RETAINERS"
  },
};


export const GENDER_F = 1;
export const GENDER_M = 2;

export const GENDER_MAP = {
  [GENDER_F]: 'SEX_F',
  [GENDER_M]: 'SEX_M'
};


export const PO_ADVANCED = 1;
export const PO_2_STEPS = 2;
export const PO_3_STEPS = 3;

export const PM_INVOICE = 1;
export const PM_CASH = 2; // DEPRECATED method
export const PM_CARD = 3;


export const DECIMAL_PRECISION = 2;

export const PAYERS = {
  'PATIENT': 3,
  'CLINIC': 1,
  'DOCTOR': 2
} as const;

export const INSTALLMENT = {
  '100PERCENT': PO_ADVANCED,
  '2STEPS': PO_2_STEPS,
  '3STEPS': PO_3_STEPS,
}


export const PAYERS_TRANSLATION_MAP = {
  3: 'PAYER_PATIENT',
  1: 'PAYER_CLINIC',
  2: 'PAYER_DOCTOR'
};
export const LINK_TARGET_BLANK = "_blank";
export const LINK_TARGET_SELF = "_self";


export const PAYMENT_OPTIONS_TRANSLATION_MAP = {
  1: 'PO_ADVANCE',
  2: 'PO_SPLIT_2',
  3: 'PO_SPLIT_3'
};

export type TMaterial = typeof SO_LAST_STAGE | typeof SO_USE_SCAN | typeof SO_IMPRESSIONS;

export const SO_LAST_STAGE = 1;
export const SO_USE_SCAN = 2;
export const SO_IMPRESSIONS = 3;

export type TArch = typeof SO_BOTH_ARCHES | typeof SO_UPPER_ARCH | typeof SO_LOWER_ARCH;

export const SO_BOTH_ARCHES = 1;
export const SO_UPPER_ARCH = 2;
export const SO_LOWER_ARCH = 3;

export const EXTRA_SERVICE_OPTION_TRANSLATION_MAP = {
  [SO_LAST_STAGE]: 'EXTRA_SERVICE_OPTION_LAST_STAGE',
  [SO_USE_SCAN]: 'EXTRA_SERVICE_OPTION_USE_SCAN',
  [SO_IMPRESSIONS]: 'EXTRA_SERVICE_OPTION_USE_IMPRESSIONS'
}

export const ARCHES = {
  1: 'TA_BOTH',
  2: 'TA_UPPER',
  3: 'TA_LOWER'
}

export const statuses = [
  {value:0, label:'S_UNFILLED_CASE'},
  {value:1, label: 'S_INSTRUCTIONS_PROCESSING'},
  {value:2, label: 'S_RETAKE_IMPRESSIONS'},
  {value:3, label: 'S_WAITING_FOR_A_PAYMENT'},
  {value:4, label: 'S_3D_SCANNING'},
  {value:5, label: 'S_3D_PLAN_MODELING'},
  {value:6, label: 'S_3D_PLAN_APPROVAL'},
  {value:7, label: 'S_3D_PLAN_MODELS_BUILDING'},
  // {value:8, label: 'S_PRODUCTION_PRINTING'},
  // {value:9, label: 'S_PRODUCTION_FABRICATING'},
  {value:10, label: 'S_FINAL_STAGE_READY'},
  {value:11, label: 'S_TREATMENT_FINISHED'},
  {value:12, label: 'S_CANCEL_TREATMENT'},
  {value:13, label: 'S_RETAINERS_ORDERED'},
  {value:14, label: 'S_RETAINERS_PRINTING'},
  // {value:15, label: 'S_RETAINERS_FABRICATING'},
  {value:16, label: 'S_RETAINERS_READY'},
  // {value:17, label: 'S_RETAINERS_3D_SCANNING'},
]

export const statuses_predict = [
  {value:0, label:'S_UNFILLED_CASE'},
  {value:1, label: 'S_INSTRUCTIONS_PROCESSING'},
  {value:2, label: 'S_RETAKE_IMPRESSIONS'},
  {value:3, label: 'S_WAITING_FOR_A_PAYMENT'},
  {value:4, label: 'S_3D_SCANNING'},
  {value:5, label: 'S_3D_PLAN_MODELING'},
  {value:6, label: 'S_3D_PLAN_APPROVAL'},
  {value:7, label: 'S_3D_PLAN_MODELS_BUILDING'},
  // {value:8, label: 'S_PRODUCTION_PRINTING'},
  // {value:9, label: 'S_PRODUCTION_FABRICATING'},
  // {value:10, label: 'S_FINAL_STAGE_READY'},
  {value:11, label: 'S_TREATMENT_FINISHED'},
  {value:12, label: 'S_CANCEL_TREATMENT'},
  {value:13, label: 'S_RETAINERS_ORDERED'},
  {value:14, label: 'S_RETAINERS_PRINTING'},
  // {value:15, label: 'S_RETAINERS_FABRICATING'},
  {value:16, label: 'S_RETAINERS_READY'},
  // {value:17, label: 'S_RETAINERS_3D_SCANNING'},
]

export const PAYMENT_SYSTEM_TYPE: "yookassa" | "sberbank" = "yookassa";

export const SBERBANK_DISCLAIMER = `Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение
с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола
шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified
By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля.
Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев,
предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом
соответствии с требованиями платежных систем МИР, Visa Int. и MasterCard Europe Sprl.`


// Common
export const PREDICT_SUPPORT_PHONE = "+1 888 333 8771";
export const PREDICT_SUPPORT_EMAIL = "support@3dpredict.com";
